<template>
    <div class="qr-code">
        <vue-qr :text="pcbaUrl" :size="150" style="display: block; margin: auto" :margin="0"></vue-qr>
    </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
    components: {
        VueQr
    },
    props: {
        pcbaUrl: String
    },
    data(){
        return {
            
        }
    },
    methods: {
        
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.qr-code{

}
</style>