import service from '@/utils/service.js';

export function getAppList(params){
    return service.get('/app/list',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getAppDetail(params){
    return service.get('/app/detail',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getAppDetailByName(params){
    return service.get('/app/unique',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getArea(params){
    return service.get('/get-area',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function getHistoryList(params){
    return service.get('/app/history',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}

export function saveApp(data){
    return service.post('/app/save',data,{
        
    })
}

export function userList(params){
    return service.get('/user/list',{
        params
    })
}

export function removeUser(data){
    return service.post('/user/remove',data,{
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}
export function modifyUser(data){
    return service.post('/user/modify',data,{
        
    })
}


